var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "card-blocked mb-0" },
    [
      _c(
        "b-card-body",
        { staticClass: "p-0" },
        [
          _c(
            "b-row",
            { staticClass: "d-flex justify-content-between" },
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "6" } },
                [
                  _c("modal-show-image", {
                    attrs: {
                      url: _vm.modal_data.proof.url,
                      display: 1,
                      title: "visualizaçao da imagem",
                    },
                  }),
                ],
                1
              ),
              _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                _c("span", [
                  _vm._v(
                    "Enviado às " +
                      _vm._s(
                        _vm._f("moment")(
                          _vm.modal_data.proof.created_at,
                          "DD/MM/YYYY"
                        )
                      ) +
                      " por " +
                      _vm._s(_vm.modal_data.proof.assigned_by)
                  ),
                ]),
                _vm.modal_data.proof.status === "REJECTED"
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "b-badge",
                          {
                            staticClass: "font-size-2",
                            attrs: { variant: "danger" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.modal_data.proof.status) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm.modal_data.proof.status === "SENT"
            ? _c(
                "div",
                { staticClass: "mt-3 d-flex justify-content-end" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "success" },
                      on: { click: _vm.onClickApprove },
                    },
                    [_vm._v(" Aprovar ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-3",
                      attrs: { variant: "danger" },
                      on: { click: _vm.onClickReject },
                    },
                    [_vm._v(" Reprovar ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }