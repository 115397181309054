<template>
  <b-card class="card-blocked mb-0">
    <b-card-body class="p-0">
      <b-row class="d-flex justify-content-between">
        <b-col sm="12" md="6">
          <modal-show-image :url="modal_data.proof.url" :display="1" title="visualizaçao da imagem" />
        </b-col>

        <b-col sm="12" md="6">
          <span>Enviado às {{ modal_data.proof.created_at | moment('DD/MM/YYYY') }} por
            {{ modal_data.proof.assigned_by }}</span>

          <!--status-->
          <div v-if="modal_data.proof.status === 'REJECTED'" class="mt-2">
            <b-badge variant="danger" class="font-size-2">
              {{ modal_data.proof.status }}
            </b-badge>
          </div>
        </b-col>
      </b-row>

      <!--actions-->
      <div v-if="modal_data.proof.status === 'SENT'" class="mt-3 d-flex justify-content-end">
        <b-button variant="success" @click="onClickApprove">
          Aprovar
        </b-button>
        <b-button variant="danger" class="ml-3" @click="onClickReject">
          Reprovar
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import TRANSACTION_PAY from '@graphql/transaction/mutations/pay.gql';
import ModalShowImage from '@components/drivers/proof-docs/modal-show-image';

export default {
  name: 'ProofOfPayment',
  components: {
    ModalShowImage
  },
  props: {
    modal_data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClickApprove() {
      this.$swal({
        type: 'question',
        titleText: 'Tem certeza que deseja aprovar este comprovante de pagamento?',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Fechar',
        showCancelButton: true,
        showConfirmButton: true,
      }).then(result => {
        if (result.dismiss)
          return;

        this.$apollo.mutate({
          mutation: TRANSACTION_PAY,
          variables: {
            id: this.modal_data.id,
            driver: this.modal_data.driver,
            invoice: this.modal_data.invoice,
            amount_paid: parseInt(this.modal_data.amount),
            payment_method_token: this.modal_data.payment_method_token,
            proof: this.modal_data.proof.id,
          },
        }).then(() => {
          this.$store.dispatch('driverDocument/approve', this.modal_data.proof.id);
          this.$emit('approved');
        }).catch(() => {
          this.$swal({
            type: 'error',
            title: 'Ocorreu um erro!',
            text: 'Verifique as informações novamente',
            showConfirmButton: false,
            timer: 2500,
          });
        });
      });
    },
    onClickReject() {
      this.$swal({
        type: 'question',
        titleText: 'Tem certeza que deseja reprovar este comprovante de pagamento?',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Fechar',
        showCancelButton: true,
        showConfirmButton: true,
      }).then(result => {
        if (result.dismiss)
          return;

        this.$store.dispatch('driverDocument/disapprove', this.modal_data.proof.id);
        this.$emit('rejected');
      });
    },
  },
};
</script>

<style scoped>
.card-blocked {
  border: 0;
}
.card-blocked .card-body {
  background-color: #EAEDF3 !important;
  border-radius: 8px;
}
</style>
